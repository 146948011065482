<template>
  <div class="log-accessi-container">
    <div class="w-100 mb-3">
      <h2>Log Accessi</h2>
      <hr />
      <div class="w-100 flex justify-content-end align-items-center">
        <b-form-group label="Accessi da mostrare">
          <b-form-input type="number" v-model="numAccessi"></b-form-input>
        </b-form-group>
        <div class="mt-1 ml-3">
          <b-button @click="getData({ idUtente: idUtente, limit: numAccessi })"
            >Carica accessi</b-button
          >
        </div>
      </div>
    </div>

    <div
      v-if="loading.getData"
      class="d-flex justify-content-center align-items-center my-3 py-3"
    >
      <b-spinner label="Loading..." variant="primary"></b-spinner>
    </div>

    <div v-else-if="logAccessi.length > 0" class="w-100 ">
      <div class="card border-0 p-3">
        <div>
          <h4>Ultimi {{ getMinAccessi }} Accessi</h4>
        </div>
        <ul class="list-group list-group-flush">
          <template v-for="(group, index) in logAccessiGroupedByMonth">
            <li class="list-group-item" :key="index">
              <h4>{{ group.month }}</h4>
            </li>
            <li
              class="list-group-item"
              v-for="accesso in group.accessi"
              :key="accesso.id"
            >
              {{
                new Date(accesso.dataLogin).toLocaleTimeString("it", {
                  hour: "numeric",
                  minute: "numeric",
                })
              }}
              -
              {{
                new Date(accesso.dataLogin).toLocaleDateString("it", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              }}
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div v-else-if="logAccessi.length == 0" class="w-100">
      <h4>Nessun accesso</h4>
    </div>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";

export default {
  name: "LogAccessi",
  props: {
    idUtente: Number,
  },
  data() {
    return {
      logAccessi: [],
      loading: {
        getData: false,
      },
      numAccessi: 10,
      error: null,
    };
  },
  computed: {
    getMinAccessi() {
      return Math.min(this.numAccessi, this.logAccessi.length);
    },
    logAccessiGroupedByMonth() {
      const groups = {};
      this.logAccessi.forEach((accesso) => {
        const month = new Date(accesso.dataLogin).toLocaleString("it", {
          month: "long",
          year: "numeric",
        });
        if (!groups[month]) {
          groups[month] = {
            month,
            year: new Date(accesso.dataLogin).getFullYear(),
            accessi: [],
          };
        }
        groups[month].accessi.push(accesso);
      });
      return Object.values(groups);
    },
  },
  mounted() {
    this.getData({ idUtente: this.idUtente, limit: this.numAccessi });
  },
  methods: {
    async getData(options = { idUtente: this.idUtente, limit: 10 }) {
      try {
        this.loading.getData = true;
        this.logAccessi.splice(0);

        const service = new AxiosService(
          "User/LastLogins/" + this.idUtente + "/" + options.limit
        );

        const res = await service.read();
        this.logAccessi = res.map((item) => ({ dataLogin: item.dataLogin }));
      } catch (err) {
        this.error = "Errore nel recupero dei dati: " + err.message;
        console.log(err);
      } finally {
        this.loading.getData = false;
      }
    },
  },
};
</script>

<style scoped>
.log-accessi-container {
  margin: 0 auto;
}
.card {
  border: 0;
}
.card-header {
  background-color: var(--whappy-primary);
  color: white;
}
.list-group-item {
  color: #333;
}
</style>
