<template>
  <div>
    <h1 v-if="showTitle">Change Password</h1>

    <b-card style="max-width: 500px; align-self: center;" class="w-100 py-4">
      <div v-if="!passwordChanged">
        <b-form @submit.prevent="handleChangePassword">
          <b-form-group
            label="New Password"
            label-for="new-password"
            description="Enter your new password."
          >
            <b-input-group>
              <b-form-input
                id="new-password"
                v-model="newPassword"
                :type="showNewPassword ? 'text' : 'password'"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  @click="togglePasswordVisibility('new')"
                  variant="outline-secondary"
                >
                  <b-icon
                    :icon="showNewPassword ? 'eye-slash' : 'eye'"
                  ></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Confirm New Password"
            label-for="confirm-new-password"
            description="Re-enter your new password to confirm."
          >
            <b-input-group>
              <b-form-input
                id="confirm-new-password"
                v-model="confirmNewPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                required
                :state="passwordMatch"
                :feedback="passwordMatch ? '' : 'Passwords do not match'"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  @click="togglePasswordVisibility('confirm')"
                  variant="outline-secondary"
                >
                  <b-icon
                    :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                  ></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-button
            variant="primary"
            type="submit"
            :disabled="!canSubmit || loading.changePassword"
          >
            Change Password
          </b-button>
          <b-spinner
            v-if="loading.changePassword"
            small
            type="grow"
            variant="light"
            class="ml-2"
          ></b-spinner>
        </b-form>
      </div>

      <div
        v-else
        class="alert alert-success"
        style="background-color: #d4edda; color: #155724;"
      >
        Password changed successfully!
      </div>
    </b-card>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";

export default {
  name: "ChangePassword",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    endPoint: {
      type: String,
      default: "UserBo/ChangePassword",
    },
    idUtente: {
      type: Number,
    },
  },
  data() {
    return {
      loading: {
        changePassword: false,
      },
      newPassword: "",
      confirmNewPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      passwordChanged: false, // New data property
    };
  },
  computed: {
    currentUser() {
      if (this.idUtente) return { id: this.idUtente };
      return this.$store.getters.loggedUser;
    },
    passwordMatch() {
      return this.newPassword === this.confirmNewPassword;
    },
    canSubmit() {
      return this.newPassword && this.confirmNewPassword && this.passwordMatch;
    },
  },
  methods: {
    async handleChangePassword() {
      if (!this.canSubmit) {
        return;
      }

      const service = new AxiosService("User");
      this.loading.changePassword = true;

      try {
        await service.putCustomEndpoint(
          `${this.endPoint}/${this.currentUser.id}/${this.newPassword}`
        );
        this.passwordChanged = true; // Set the flag to true
        this.$bvToast.toast("Password changed successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.newPassword = "";
        this.confirmNewPassword = "";
      } catch (error) {
        console.error("Error changing password:", error);
        this.$bvToast.toast("Error changing password", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading.changePassword = false;
      }
    },
    togglePasswordVisibility(field) {
      if (field === "new") {
        this.showNewPassword = !this.showNewPassword;
      } else if (field === "confirm") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
};
</script>
