<template>
  <div
    class="
      d-flex
      flex-column
      mb-4
      mr-5
      align-items-center
      justify-content-center
      text-center
    "
    :class="{ disabled: !item.status }"
    style="max-width: 7rem"
  >
    <img
      :src="item.image"
      class="mt-1 mb-2 circle"
      style="width: 4rem; height: 4rem"
    />
    <h6 class="m-0 mb-1">{{ item.text }}</h6>

    <!-- Progress bar -->
    <div v-if="item.perc_completamento" class="progress" style="width: 100%;">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{ width: item.perc_completamento + '%' }"
        :aria-valuenow="item.perc_completamento"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress {
  height: 1rem;
  background-color: var(--whappy-pb-bg);
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background-color: var(--whappy-primary); /* Use your desired color */
  height: 100%;
  position: relative;
}
</style>
